<template>
  <div class="adad2">
    
    <router-link to="/picture"></router-link>
  </div>
</template>
<style >
.adad2 {
  width: 100vw;
  height: 100vh;
  background: url("../assets/123.jpg");
  background-size: 100% 100%;
}
a {
  display: block;
  width: 100%;
  height: 100%;
}
</style>